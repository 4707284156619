import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import RouterApp from './RouterApp';
import TopPage from './pages/TopPage';
import AboutPage from './pages/AboutPage';
import NotePage from './pages/NotePage';
import GithubPage from './pages/GithubPage';
import SnsPage from './pages/SnsPage';

const routesBasic = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RouterApp />}>
      <Route path="" element={<TopPage />}/>
      <Route path="about" element={<AboutPage />}/>
      <Route path="note" element={<NotePage />}/>
      <Route path="github" element={<GithubPage />}/>
      <Route path="sns" element={<SnsPage />}/>
    </Route>
  )
);

export default routesBasic;
