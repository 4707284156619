import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import QueryBasic from '../QueryBasic';

export default function TopPage() {
  const cli = new QueryClient();
  return (
    <>
      <QueryClientProvider client={cli}>
        <QueryBasic />
      </QueryClientProvider>
    </>
  );
  // const [, setCount] = useOutletContext();
  // useEffect(() => setCount(c => c + 1), [setCount]);
  // return <p>トップページです。</p>;

  // return (
  //   <>
  //     <MyHeader />
  //     <p>トップページです。</p>
  //   </>
  // );
}
