import { useQuery } from 'react-query';

// delay 秒だけ処理を休止する sleep 関数
const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));

// Note を取得するの関数
const fetchNote = async () => {
  await sleep(2000);
  const res = await fetch(`https://note.com/api/v2/creators/tak_nakamori/contents?kind=note&page=1`);
  if (res.ok) { return res.json(); }
  throw new Error(res.statusText);
};

export default function QueryBasic() {
  // fetchNote 関数でデータを取得
  const { data, isLoading, isError, error } = useQuery('note', fetchNote);

  if (isLoading) {
    return <p>Loading ...</p>;
  }

  if (isError) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      data
    </div>
  );
}
